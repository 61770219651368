<template>
  <div class="article-top article-label">
    <span class="text-gray-500 lowercase"
      >{{ publishingDateString
      }}<template v-if="updateDate"
        >, {{ $t('Updated') }} {{ updateDateString }}</template
      ></span
    >
    <span
      v-if="section"
      :style="{ color: section.color }"
      :class="{ 'text-blue': !isMm() }"
      class="ml-2 font-bold"
    >
      <NuxtLink :to="`/${section.url}`">{{ section.title }}</NuxtLink>
    </span>
  </div>
</template>

<script setup lang="ts">
import type { ArticleDTO, PaperDTO, QADto } from '~/typesAuto/apicore/v1'
import type { Calender } from '~/typesAuto/apicore/v2'
import type { ContentArticle, RSSFull } from '~/typesManual/content_api/article'

const route = useRoute()
const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()

const props = defineProps<{
  article: ArticleDTO | Calender | QADto | ContentArticle | RSSFull
  primaryPaper?: PaperDTO
}>()

function isArticle(article: unknown): article is ArticleDTO {
  return (article as ArticleDTO).mainTeaser !== undefined
}
function isQA(article: unknown): article is QADto {
  return (article as QADto).qtitle !== undefined
}
function isRSS(article: unknown): article is RSSFull {
  return (article as RSSFull).Uri !== undefined
}
const publishingDate = computed(() => {
  switch (route.name) {
    case 'qa':
    case 'paper_qa':
    case 'qa_paper':
    case 'paper_raadhussvar':
      if (isQA(props.article)) {
        const answerDate = props.article.adate
          ? new Date(props.article.adate)
          : new Date()
        // I don't think there's another way to determine that the question has been answered.
        // The Answsered variable seems to be 2 no matter what.
        // Maybe we could look at Atext, but that doesn't seem foolproof
        if (answerDate.getFullYear() !== 1900) {
          return props.article.adate
        } else {
          return props.article.qdate
        }
      }
      return
    case 'rss_paper':
      if (isRSS(props.article)) {
        return props.article.PublishTime
      }
      return
    case 'article':
    case 'article_index':
    case 'article_paper':
    case 'article_debate':
    case 'names_index':
    case 'names_paper':
    default:
      if (isArticle(props.article)) {
        const paper = props.article.papers?.find(
          (paper) => paper.paperId === indexStore.currentPaper?.RecordId
        )
        if (paper) {
          return paper.publishingDate
        }
      }
      return
  }
})

const publishingDateString = computed(() => {
  if (!publishingDate.value) {
    return ''
  }

  return formatDateTime(new Date(publishingDate.value))
})

const updateDate = computed(() => {
  switch (route.name) {
    case 'rss_paper':
      // Yes this is ugly. I wish we had better types
      const articleWithUpdateDate = props.article as { UpdateDate: string }
      if (articleWithUpdateDate.UpdateDate != undefined) {
        return articleWithUpdateDate.UpdateDate
      }
      return
    case 'article':
    case 'article_index':
    case 'article_paper':
    case 'article_debate':
    case 'names_index':
    case 'names_paper':
      if (isArticle(props.article)) {
        const paper = props.article.papers?.find(
          (paper) => paper.paperId === indexStore.currentPaper?.RecordId
        )
        if (paper) {
          return paper.updateDate
        }
      }
      return
    default:
      if (isArticle(props.article)) {
        const paper = props.article.papers?.find(
          (paper) => paper.paperId === indexStore.currentPaper?.RecordId
        )
        if (paper) {
          return paper.updateDate
        }
      }
      return
  }
})

const updateDateString = computed(() => {
  if (!updateDate.value) {
    return ''
  }

  return formatDateTime(new Date(updateDate.value))
})

const primaryPaper = computed(() => {
  if (!isArticle(props.article)) {
    return
  }
  const paper = props.article.papers?.find((paper) => paper.primary === 1)
  if (paper) {
    return indexStore.papers?.find((x) => x.RecordId === paper.id)
  }
})

const section = computed(() => {
  switch (route.name) {
    case 'qa':
    case 'paper_qa':
    case 'qa_paper':
    case 'paper_raadhussvar':
      if (isQA(props.article) && props.article.typeId === 4) {
        return {
          url: `${indexStore.currentPaper?.Url}/raadhussvar`,
          title: 'Rådhussvar',
        }
      } else {
        if (indexStore.currentPaperSlug) {
          return {
            url: `${indexStore.currentPaperSlug}/${nuxtApp.$t('Routes.QA')}`,
            title: nuxtApp.$t('MinisterQA'),
          }
        }

        return {
          url: `${nuxtApp.$t('Routes.QA')}`,
          title: nuxtApp.$t('MinisterQA'),
        }
      }
    case 'article':
    case 'article_index':
    case 'names_index':
    case 'article_paper':
    case 'names_paper':
    case 'article_debate':
      if (isMm()) {
        if (primaryPaper.value && primaryPaper.value.RecordId !== 1) {
          return {
            url: primaryPaper.value.Url,
            title: primaryPaper.value.Name,
            color: primaryPaper.value.ColorRGB,
          }
        }
      }
      if (indexStore.currentPaper?.RecordId !== 1) {
        return {
          url: indexStore.currentPaper?.Url,
          title: indexStore.currentPaper?.Name,
        }
      } else {
        return
      }
    default:
      return
  }
})
</script>
